import { Application } from "./App";
import { Page } from "./pages/Page";

export interface FieldInfo {
    name: string; // ID поля
    type: string;
    input?: string;
    notNull: boolean; // Требовать заполнения
    label?: string; //   Текст возле поля

    addFromPath?: string; // Для пипа select. Путь, откуда можно выбирать документы.
    addFilterScript?: string; // Для пипа select. Скрипт для настройки фильтра перед выбором документов.
    allowCreate?: boolean; // Разрешить редактирование только при создании
    selectPath?: string;
    selectPaths?: string[];
  
    checkValues: boolean;  // проверять значения по справочнику?
    checkboxText?: string; // Текст возле checkbox
    colorField?: string;   // Имя другого поля, в котором указан цвет
    comboValues?: [string,string][]; // Значения, полученные из справочника
    comment?: string;
    // dbName        Имя столбца в для внешн. таблицы
    // dbType        (number/varchar/date) Тип столбца в для внешн. таблицы
    defaultValue: any; //  Значение по-умолчанию

    // Для полей с описанияни значения
    descDict?: string; // Имя справочника для заполнения другого поля
    descField?: string; //  Поле, которое будет заполнено по справочнику

    digits: boolean; // Только цифры
    digitGrouping?: boolean;
    groupingChar?: string;
    docsPath?: string; // Для subform - ссылка на path

    filterField?: string; // Для типа subdocuments. Имя поля вложенного документа, которое ссылается на текущий документ.
    prepareScript?: string; // Для типа subdocuments. Скрипт, который будет вызван перед созданием встроенной формы.
  
    filterAlias?: string[]; // список полей для поиска с условием ИЛИ
    filterOper?: string;    // Начальный тип поискадля фильтра
    hideLabel: boolean;     // Спрятать текст на форме
    hideWhenNull: boolean;  //  Не показывать subform если null
    highlight?: string;     //  Подсветка синтаксиса для просмотра XML и JSON.
    helpText?: string;      // Дополнительное описание. Раскрывается при нажатии на кнопку со стрелкой.
    extraHelp?: string;     // Дополнительное описание. Открывается в диалоговом окне при нажатии на кнопку с книгой. Задаётся в формате HTML.
    extraHelpSize: any;     // Размер диалогового окна для поля extraHelp.
  
    ignoreCase: boolean;    //  Поиск без учета регистра
    // index: boolean; //          Нужно ли индексировать по этому полю.
    leftIcon?: string;  // Иконка в текстовом поле
    maxlen?: number     //  Макс. длина значения
    minlen?: number     //  Мин. длина значения
    monospace: boolean; //  Шрифт с фиксир. шириной
    multiline: boolean; //  Переносы строки допустимы

    // Для combo
    noCheck: boolean; //    Не проверять значение
    noGridDesc: boolean; //     Для значений из справочника - в таблице только ключ
    noGridKeys: boolean; //     Для значений из справочника - в таблице только описание
    noKeys: boolean; //     Для combo - только описание
    noFilterKeys: boolean; //     Для для фильтра - только описание
    rowFormat?: "key" | "label" | "key_label";
    viewFormat?: "key" | "label" | "key_label";
    hugeDict: boolean;

    // для таблицы
    minColumnWidth?: number;  // Мин. ширина в пикселал (по-умолчанию 20)
    scaleColumnWidth?: number; // Коэфф. ширины (по-умолчанию 100)

    noRightPos: boolean; // На форме показывать только слева
    onBlur?: string; //     Скрипт при потере фокуса
    onFocus?: string; //    Скрипт при получении фокуса
    onChange?: string; //   Скрипт при изменении значения
    onEnter?: string; //    Скрипт при нажатии на Enter
    pattern?: string; //    Рег. выражения для проверки значения
    patternText?: string; // Текст для пользователя при проверке pattern
    place?: string; //       ID места на форме. По-умолчанию "Main"
    placeholder?: string; // Подсказка внутри поля ввода
    readonly: boolean; //   Не редактируемое поле
    smallText?: string //   Подсказка под полем
    subaction?: string; //  Скрипт для кнопки возле поля (справа)
    subactionIcon?: string; // Иконка для кнопки возле поля. По-умолчанию - многоточие.
    tooltip?: string; //    Всплывающая подсказка
    typeName?: string;   // Тип для таблицы или subform
    upper: boolean; //      Только заглавные буквы
    valueMode?: string; //  (ID/INLINE/ID_ARRAY/INLINE_ARRAY/CHILDREN) Тип данных для subform
    values: [string,string][]; //  (Array of [key,value] ) Явно встроенный справочник
    valuesDict?: string; // Ссылка на справочник
    valuesIndex?: any; //   Для внутр. использования (см. TableSource)
    visible?: string | boolean;
    wide: boolean; //       Поле занимает всю ширину формы
    zeros: boolean; //      Значение дополняется нулями до minlen (как для номера клиента)
    style: any;
    styleTD: any;
}

export interface FieldsIndex {
    [key: string]: FieldInfo;
}

export class TypeInfo {
    desc: any;
    name: string;
    fieldsIndex: FieldsIndex;
    fields: FieldInfo[] = [];
    formName: string;
    prepared = false;
    parents: TypeInfo[] = [];
    _preparing = false;
    module: any;

    onPageInit?: (p:Page)=>void;

    get baseTypes() { return this.parents; }

    onShow?: (m: any) => void;

    constructor( desc ) {
        this.desc = desc;
        this.name = desc.id;
        this.fieldsIndex = {};
        this.formName = desc.formName;
    }

    async prepare( app: Application ) {
        if ( this.prepared )
            return;

        if ( this._preparing )
            throw Error( "TypeInfo prepare loop: " + this.name );

        this._preparing = true;

        let arr: string[] = [];
        if ( this.desc.extends ) {
            if ( typeof this.desc.extends === "string" ) {
                arr = this.desc.extends.split( /\s+/ );
            } else if (Array.isArray(this.desc.extends)) {
                arr = this.desc.extends;
            } else {
                console.error( "Invalid type extends, type: " + this.name );
            }
        }

        if ( this.desc.extends2 )
            arr.push( this.desc.extends2 );

        if ( arr.length > 0 ) {
            this.parents = [];
            for ( let name of arr ) {
                let p = app.getType( name );
                if ( p )
                    this.parents.push( p );
                else
                    console.warn( `Unknown type ${name} (parent for ${this.name})` );
            }
        }

        if ( !this.formName ) {
            this.formName = this.getAttr( "formName" );
        }

        this.fields = this.allFieldsDesc();
        this.fieldsIndex = Object.fromEntries( this.fields.map( f => [ f.name, f ] ) );

        // if ( this.desc.clientModule ) {
        //     this.module = app.getModule( this.desc.clientModule );
        //     if ( this.module.initType ) {
        //         await this.module.initType( this );
        //     }
        // }

        if ( this.desc.clientScript ) {
            let typeInfo = this;
            let typeDesc = this;
            eval( this.desc.clientScript );
        }

        this._preparing = false;
        this.prepared = true;
    }

    // async prepareSelects() {
    //     for ( let field of this.fields ) {
    //         if ( (field.input !== "select" && field.type !== "select") || field.selectPathsDesc ) continue;

    //         let paths = field.selectPaths || [ field.selectPath ];

    //         let pd : any[] = [];
    //         for ( let path of paths ) {
    //             let pinfo = await app.getPath( path );
    //             if ( pinfo != null ) {
    //                 pd.push( {
    //                     path,
    //                     tooltip: pinfo.desc.label,
    //                     icon: pinfo.desc.selectIcon
    //                 } );
    //             } else {
    //                 pd.push( {
    //                     path,
    //                     tooltip: path
    //                 } );
    //             }
    //         }
    //         field.selectPathsDesc = pd;
    //     }
    // }

    getField( name ) {
        return this.fieldsIndex[name];
    }

    getAllFields() {
        return this.fields;
    }

    getAttr( name: string ) {
        let v = this.desc[name];
        if ( v !== undefined )
            return v;

        if ( this.parents ) {
            for ( let parent of this.parents ) {
                v = parent.getAttr( name );
                if ( v !== undefined )
                    return v;
            }
        }
    }

    private allFieldsDesc() : FieldInfo[] {
        let res: FieldInfo[] = [];

        if ( this.parents ) {
            for ( let parent of this.parents ) {
                res = [ ...res, ...parent.allFieldsDesc() ]
            }
        }

        let order: string[] = [];

        if ( this.desc.fields ) {
            for ( let field of this.desc.fields ) {
                if (field.type === "bool") 
                    field.type = "boolean";
                res.push( field );
                order.push( field.name );
            }
        }

        if ( res.length < 2 )
            return res;

        let idx = {};
        for ( let field of res ) {
            if ( field.name in idx ) {
                idx[field.name] = { ...idx[field.name], ...field };
            } else {
                idx[field.name] = field;
            }

            if ( order.indexOf( field.name ) == -1 )
                order.push( field.name );
        }

        return order.map( n => idx[n] );
    }

    // descToField( desc ) {
    //     let res = { ...desc };
    //     if ( !res.type )
    //         res.type = "string";

    //     if ( !res.place )
    //         res.place = "Main";

    //     return res;
    // }
}
