import { FieldInfo } from "../TypeInfo";
import { ReactEvt } from "../utils";
import { DocListPage } from "./DocListPage";
import { FieldValue } from "./DocSource";
import { Application } from '../App';

declare var app: Application;

export class FilterValue {
    fieldInfo: FieldInfo;
    fieldInfoForValues: FieldInfo;
    name: string;
    inputId: string;
    page: DocListPage;
//    value: any; // Запись как в фильтре
    disabled = false;
    inputValue1: FieldValue; // 1-е Вводимое значение
    inputValue2: FieldValue; // 2-е Вводимое значение
    inputValues: FieldValue[] = []; // 3... Вводимые значения
    needDates = false;
//    dateValue1?: Date;
//    dateValue2?: Date;
    oper: string;
    operItem: [ string, string, string? ];
    operValues: any[];
    visibleInputs: number = 0;
//    _isSet = false;
    revt: ReactEvt;
    _ver = 0;
    isTop = false;
    readonly = false;

    constructor( fi: FieldInfo, readonly: boolean, page: DocListPage ) {
        this.name = fi.name;
        this.page = page;
        this.inputId = fi.name + "_" + FieldValue._no++;
        this.revt = new ReactEvt( ()=>this._ver++ );
        this.fieldInfo = fi;
        this.needDates = fi.type === "date" || fi.type === "datetime";

        let key = [ 
            { boolean: "b", date: "d", datetime: "d" }[fi.type] || "s",
            (fi.values || fi.valuesDict) ? "v" : "",
            fi.notNull ? "nn" : ""
        ].join( "" );

        let allowOpers = TYPE_OPERS[ key ];
        this.operValues = allowOpers.map( op => [ op, ...OPER_NAMES[op] ] );

        this.oper = allowOpers[0];
        this.operItem = this.operValues[0];
        this.readonly = readonly;
        if ( this.oper === "=" )
            this.visibleInputs = 1;

        this.fieldInfoForValues = this.prepFieldInfo();

        this.inputValue1 = new FieldValue( this.fieldInfoForValues, null, page );
        if ( !readonly )
            this.inputValue1.startEdit();
        this.inputValue2 = new FieldValue( this.fieldInfoForValues, null, page );
        if ( !readonly )
            this.inputValue2.startEdit();
    }

    setReadonly( ro ) {
        this.readonly = ro;
        //this.fieldInfoForValues = this.prepFieldInfo();
        //this.inputValue1 = new FieldValue( this.fieldInfoForValues, null, this.page );
        if ( ro ) {
            this.inputValue1.doneEdit();
            this.inputValue1.doneEdit();
            this.inputValues.forEach( v => v.doneEdit() );
        } else {
            this.inputValue1.startEdit();
            this.inputValue1.startEdit();
            this.inputValues.forEach( v => v.startEdit() );
        }

        this.revt.notify();
    }

    clear() {
        let first = this.operValues[0];
        this.inputValue1.assign( null );
        this.inputValue2.assign( null );
        this.inputValues = [];
        this.setOper( first[0] );
    }

    assign( flt: any ) {
        if ( flt === undefined ) {
            this.clear();
            return;
        }

        this.setOper( flt.oper );

        let values: any[];
        switch( this.oper ) {
            case "notnull":
            case "isnull":
            case "true":
            case "false":
                return;

            case "between":
                values = [ flt.text, flt.text2 ];
                break;

            case "in":
            case "not in":
                values = flt.values;
                let needInputs = this.readonly ? values.length : values.length + 1;
                this.setVisibleInputs( needInputs );
                break;

            default:
                values = [ flt.text ];
        }

        values.forEach( (v, i) => {
            this.getFieldValueAt( i ).assign( v );
        } );
        this.revt.notify();
    }

    prepFieldInfo() : FieldInfo {
        let fi = { ...this.fieldInfo, 
            // readonly: this.readonly,
            notNull: false, 
            pattern: undefined, 
            minlen: 0, 
            checkValues: false 
        };

        if ( fi.type == "select" )
            fi.type = "string";

        if ( fi.input == "select" )
            fi.input = "string";

        return fi;
    }

    setVisibleInputs( n ) {
        this.visibleInputs = n;
        let need = n - 2 - this.inputValues.length;
        for ( let i = 0; i < need; ++ i ) {
            let item = new FieldValue( this.fieldInfoForValues, null, this.page );
            this.inputValues.push( item );
            if ( !this.readonly ) item.startEdit();
        }
        this.revt.notify();
    }

    addOne() {
        this.visibleInputs++;
        let item = new FieldValue( this.fieldInfoForValues, null, this.page );
        this.inputValues.push( item );
        if ( !this.readonly ) item.startEdit();
        this.revt.notify();
    }

    isSet() {
        if ( !this.oper )
            return false;

        switch( this.oper ) {
            case "notnull":
            case "isnull":
            case "true":
            case "false":
                return true;
        
            default:
                for ( let i = 0; i < this.visibleInputs; ++i ) {
                    let v = this.getFieldValueAt( i ).getValue();
                    if ( v != null && v != "" )
                        return true;
                }
                return false;
        }
    }

    getFilterValue() {
        let v : any = { oper: this.oper };

        switch( this.oper ) {
            case "notnull":
            case "isnull":
            case "true":
            case "false":
                break;

            case "between":
                v.text = this.inputValue1.getValue();
                v.text2 = this.inputValue2.getValue();
                break;

            case "in":
            case "not in":
                v.values = [];
                for ( let i = 0; i < this.visibleInputs; ++i ) {
                    let v1 = this.getFieldValueAt( i ).getValue();
                    if ( v1 != null )
                        v.values.push( v1 );
                }
                break;

            default:
                v.text = this.inputValue1.getValue();
        }
        
        return v;
    }

    // setInputValue( n: number, v: any ) {
    //     if ( this.oper === "in" || this.oper === "not in" ) {
    //         if ( n == this.inputValues.length - 1 && v )
    //             this.inputValues.push( null );

    //         this.inputValues[n] = v;
    //         let vv = this.inputValues.filter( v=>v );
    //         this._isSet = vv.length > 0;
    //         this.value = { oper: this.oper, values: vv };
    //         this.revt.notify();
    //         return;
    //     }

    //     if ( n == 0 ) {
    //         this.inputValue1 = v;
    //         if ( this.needDates )
    //             this.dateValue1 = this.inputToDate( v );

    //         this.value.text = this.inputValue1;
    //     } else if ( n == 1 ) {
    //         this.inputValue2 = v;
    //         if ( this.needDates )
    //             this.dateValue2 = this.inputToDate( v );
    //         this.value.text2 = this.inputValue2;
    //     }

    //     if ( this.oper === "between" ) {
    //         this._isSet = Boolean( this.inputValue1 || this.inputValue2 );
    //     } else {
    //         this._isSet = Boolean( this.inputValue1 );
    //     }
    //     this.revt.notify();
    // }

    setOper( oper: string ) {
        let prevOper = this.oper;
        this.oper = oper;

        let item = this.operValues.find( item => item[0] === oper );
        if ( !item ) {
            console.error( "Invalid filter oper: ", oper );
            return;
        }

        this.operItem = item;

        if ( !this.oper ) {
            this.visibleInputs = 0;
        } else 
        switch( oper ) {
            case "notnull":
            case "isnull":
            case "true":
            case "false":
                this.visibleInputs = 0;
                break;
        
            case "between":
                this.visibleInputs = 2;
                break;

            case "in":
            case "not in":
                if ( prevOper === "in" || prevOper === "not in" ) {
                    break;
                }
                
                this.inputValues = [];
                this.visibleInputs = 2;
                break;

            default:
                this.visibleInputs = 1;
                break;
        }

        this.revt.notify();
    }

    getFieldValueAt( n: number ) : FieldValue {
        switch( n ) {
            case 0:
                return this.inputValue1;

            case 1:
                return this.inputValue2;

            default:
                return this.inputValues[n - 2];
        }
    }


}

const TYPE_OPERS = {
    b: [ "", "true", "false", "isnull", "notnull" ],
    bnn: [ "", "true", "false" ],
    s: [ "=", "substr", "!=", ">", ">=", "<", "<=", "between", "in", "not in", "isnull", "notnull" ],
    sv: [ "=", "!=", "in", "not in", "isnull", "notnull" ],
    snn: [  "=", "substr", "!=", ">", ">=", "<", "<=", "between", "in", "not in"],
    svnn: [ "=", "!=", "in" ],
    d: [ "=", "!=", ">", ">=", "<", "<=", "between", "isnull", "notnull" ],
    dnn: [ "=", "!=", ">", ">=", "<", "<=", "between" ],
}

const OPER_NAMES = {
    "": [ "Не используется" ],
    "=": [ "Равно", "equals" ],
    "!=": [ "Не равно", "not-equal-to" ],
    ">": [ "Больше", "greater-than" ],
    ">=": [ "Больше или равно", "greater-than-or-equal-to" ],
    "<": [ "Меньше", "less-than" ],
    "<=": [ "Меньше или равно", "less-than-or-equal-to" ],
    "substr": [ "Подстрока", "variable" ],
    "notnull": [ "Есть значение", "tick-circle" ],
    "isnull": [ "Нет значения", "circle" ],
    "true": [ '"Значение "ДА"', "tick" ],
    "false": [ '"Значение "НЕТ"', "cross" ],
    "between": [ "Интервал", "arrows-horizontal" ],
    "in": [ "Значение из списка", "filter-list" ],
    "not in": [ "НЕ из списка", "filter-list" ]
}

