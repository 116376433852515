import { FieldInfo } from "../TypeInfo";
import { ReactEvt, utils } from "../utils";
import { Page } from "./Page";
import { Application } from '../App';
import { Intent } from "@blueprintjs/core";
import { ViewDocPage } from "./ViewDocPage";
import { DocListPage } from "./DocListPage";
import { IdArrayPage, InlineArrayPage } from "./EditDocsPage";
import { BaseListPage } from "./BaseListPage";
import { DIALOG_ACTIONS } from "../constants";
import { createAddFilesPage } from "./files";

declare var app: Application;

export interface SelectPathDesc {
    path: string;
    tooltip: string;
    icon?: string;
}

export interface InputPrpops {
    value:FieldValue;
    ver: any;
}

export class FieldValue {
    name: string;
    inputId: string;
    value: any; // Значение как docData или в updates
    update: any;
    dateValue?: Date;
//    selectPathsDesc?: SelectPathDesc[];
    page: Page;
    subform?: Page;
    // comboValues?: [string,string][];
    invalid?: boolean;
    invalidText?: string;
    invalidInputText?: string;
    inputValue: any; // Текущее вводимое значение

    fieldInfo: FieldInfo;
    fmt: FieldFormat;

    revt: ReactEvt;
    descRef?: FieldValue;
    lastDescValue: any;
    editMode: boolean = false;
    disabled: boolean = false;
    visible: boolean = true;

    _ver = 0;
    static _no = 0;

    constructor( fieldInfo: FieldInfo, value: any, page: Page ) {
        this.name = fieldInfo.name;
        this.inputId = fieldInfo.name + "_" + FieldValue._no++;
        this.fieldInfo = fieldInfo;
        this.value = value;
        this.page = page;
        this.fmt = this.prepFmt( fieldInfo );
        this.inputValue = this.fmt.toInput( value );
        this.revt = new ReactEvt( ()=>this.getState() );
    }

    async init() {
        await this.evalVisible();
    }

    onDestroy() {
        this.revt.close();
    }

    notify() {
        this.revt.notify();
    }

    setFieldInfo( fieldInfo: FieldInfo ) {
        let prevReadonly = Boolean( this.fieldInfo.readonly);
        this.fieldInfo = fieldInfo;
        this.fmt = this.prepFmt( fieldInfo );

        let readonly = Boolean( fieldInfo.readonly );
        if ( readonly != prevReadonly && !this.disabled ) {
            if ( readonly && this.editMode )
                this.doneEdit();
            else if ( !readonly && !this.editMode )
                this.startEdit();
        }
        this.notify();
    }

    setInputValue( v: any ) {
        this.inputValue = v;
        this.invalidInputText = this.fmt.checkInput( v );
        this.update = this.fmt.toValue( v );
        delete this.dateValue;
        this.revt.notify();

        if ( this.descRef )
            this.setDescValue( this.update );

        this.onChange();
    }

    startEdit() {
        this.editMode = true;
    }

    cancelEdit() {
        this.editMode = false;
        this.update = undefined;
        this.invalidInputText = undefined;
        this.inputValue = this.fmt.toInput( this.value );
        delete this.dateValue;
        this.revt.notify();

        if ( this.descRef )
            this.setDescValue( this.value );
    }

    doneEdit() {
        this.editMode = false;
        if ( this.update !== undefined )
            this.value = this.update;
        this.update = undefined;

        this.invalidInputText = undefined;
        this.inputValue = this.fmt.toInput( this.value );
        delete this.dateValue;
        this.revt.notify();

        if ( this.descRef )
            this.setDescValue( this.value );
    }

    setUpdate( v: any ) {
        if ( v instanceof Date ) {
            this.dateValue = v;
            v = this.fmt.toValue( v );
        } else {
            delete this.dateValue;
            if ( v == "" ) v = null; // fix for combo
        }
        this.update = v;
        this.invalidInputText = undefined;
        this.inputValue = this.fmt.toInput( v );
        this.revt.notify();

        if ( this.descRef )
            this.setDescValue( this.update );

        this.onChange();
    }

    assign( value: any ) {
        this.value = value;
        this.update = undefined;
        this.invalidInputText = undefined;
        this.inputValue = this.fmt.toInput( value );
        this.revt.notify();

        if ( this.descRef )
            this.setDescValue( value );
    }

    getValue() {
        return (this.update === undefined) ? this.value : this.update;
    }

    isEmpty() {
        return this.getValue() == null;
    }

    getUpdate() {
        return this.update;
    }

    getValueAsDate() : Date | undefined {
        if ( this.dateValue )
            return this.dateValue;

        let v = this.getValue();
        if ( v == undefined ) return undefined;

        let d = new Date( v );
        this.dateValue = d;
        return d;
    }

    isInvalid() {
        return this.invalid || Boolean( this.invalidInputText );
    }

    getIntent() : Intent {
        if ( this.invalid || this.invalidInputText ) return "danger";
        return this.fieldInfo.highlight as Intent;
    }

    getInvalidText() {
        return this.invalidInputText || this.invalidText;
    }

    getState() {
        return ++ this._ver;
    }

    protected prepFmt( f: FieldInfo ) : FieldFormat {
        if ( f.type == "date" )
            return new FieldFormatDate( f );

        if ( f.type == "datetime" )
            return new FieldFormatDateTime( f );

        if ( f.type == "integer" )
            return new FieldFormatInteger( f );
        
        if ( f.type == "amount" )
            return new FieldFormatAmount( f );
        
        if ( f.type == "number" )
            return new FieldFormatNumber( f );
        
        if ( f.type == "boolean" )
            return new FieldFormatBoolean( f );

        if ( f.type == "files" )
            return new FieldFormatFiles( f );

        if ( f.type == "subform" || f.type == "table" )
            return new FieldFormatSubform( f );

        if ( f.values || f.comboValues )
            return new FieldFormatValues( f );

        return new FieldFormatString( f );
    }

    async setDescValue( value ) {
        if ( value === this.lastDescValue || !this.descRef )
            return;

        this.lastDescValue = value;
        this.descRef.setUpdate( "[loading]" );

        try {
            let obj = await app.getDictValue( this.fieldInfo.descDict, value );
            if ( value !== this.lastDescValue )
                return;

            this.descRef.setUpdate( obj.label );
        } catch ( err: any ) {
            console.warn( err );
            app.showWarning( err.message );
            this.descRef.setUpdate( null );
        }
    }

    onSubaction() {
        this.page.evalScript( this.fieldInfo.subaction );
    }
    
    onEnterPress() {
        if ( typeof this.fieldInfo.onEnter === "string" ) {
            this.page.evalScriptAsync( this.fieldInfo.onEnter, { field: this.fieldInfo, value: this.getValue() }, true ).catch( console.error );
        } else {
            this.page.onPressEnter();
        }
    }

    // onDictSelect() {
    //     // ...
    // }

    async evalVisible() {
        let newVisible = true;
        if ( this.fieldInfo.visible === false || this.fieldInfo.visible === "false" ) {
            newVisible = false;
        } else if ( typeof this.fieldInfo.visible === "string" ) {
            newVisible = await this.page.evalScriptAsync( this.fieldInfo.visible, { field: this.fieldInfo, value: this.getValue() } );
        }

        if ( this.visible != newVisible ) {
            this.visible = newVisible;
            this.page.notify();
        }
    }

    onChange() {
        if ( typeof this.fieldInfo.onChange === "string" ) {
            this.page.evalScriptAsync( this.fieldInfo.onChange, { field: this.fieldInfo, value: this.getValue() }, true ).catch( console.error );
        }
    }

    onBlur() {
        if ( typeof this.fieldInfo.onBlur === "string" ) {
            this.page.evalScriptAsync( this.fieldInfo.onBlur, { field: this.fieldInfo, value: this.getValue() }, true ).catch( console.error );
        }
    }

    onFocus() {
        if ( typeof this.fieldInfo.onFocus === "string" ) {
            this.page.evalScriptAsync( this.fieldInfo.onFocus, { field: this.fieldInfo, value: this.getValue() }, true ).catch( console.error );
        }
    }
}

export class SelectFieldValue extends FieldValue {
    selectPathsDesc: SelectPathDesc[];
    labelValue: FieldValue;
    pathValue: FieldValue;

    constructor( fieldInfo: FieldInfo, value: any, page: Page, labelValue: FieldValue, pathValue: FieldValue ) {
        super( fieldInfo, value, page );
        this.labelValue = labelValue;
        this.pathValue = pathValue;

        let paths : string[] = [];
        if ( fieldInfo.selectPath )
            paths.push( fieldInfo.selectPath );

        if ( fieldInfo.selectPaths )
            paths.push( ...fieldInfo.selectPaths );

        this.selectPathsDesc = [];
        for( let path of paths ) {
            let pathInfo = app.getPathInfo( path );
            this.selectPathsDesc.push( {
                path,
                tooltip: pathInfo?.desc.label,
                icon: pathInfo?.desc.selectIcon
            } );
        }
    }

    onSelectView() {
        let options = {
            mode: "view",
            docId: this.getValue(),
            target: "dialog",
            path: this.pathValue.getValue()
        };
        app.showView( options );
    }

    onSelectClick( pdesc: SelectPathDesc ) {
        let options = {
            mode: "list",
            viewMode: "select",
            target: "dialog",
            selectedValue: this.getValue(),
            path: pdesc.path,
            onOk: (res, pg) => {
                if ( res?.[0] ) {
                    this.onSelectResult( res[0], pdesc );
                    pg.close();
                }
            }
        }
        app.showView( options );
    }

    onSelectResult( doc, pdesc?: SelectPathDesc ) {
        if ( !doc ) return;

        if ( (this.page as any).prepSelectedDoc )
            doc = (this.page as any).prepSelectedDoc( doc );

        this.setUpdate( doc.id );
        this.inputValue = doc.label || doc.name;
        this.labelValue.setUpdate( doc.label || doc.name );
        this.pathValue.setUpdate( pdesc?.path || doc.path );
    }

    clear() {
        this.setUpdate( null );
        this.labelValue.setUpdate( null );
        this.pathValue.setUpdate( null );
    }
}

export class DictFieldValue extends FieldValue {
    filledValue: any; // Последнее подобранное значение
    dictRecord: any;  // Запись из справочника
    dictPath: string;

    constructor( fieldInfo: FieldInfo, value: any, page: Page ) {
        super( fieldInfo, value, page );
        this.dictPath = app.dicts[fieldInfo.valuesDict || "-"]?.dictPath;
        this._fillInput();
    }

    private _fillInput() {
        let value = this.getValue();
        if ( value == null || value == "" ) {
            this.inputValue = "";
            return;
        }

        if ( value === this.filledValue ) {
            this._fillInputReal( this.dictRecord, value );
            this.notify();
            return;
        }

        this.filledValue = undefined;
        this.dictRecord = undefined;
        app.getDictValue( this.fieldInfo.valuesDict, value ).then( res => this._onDictValue( res, value ) ).catch( err => console.warn( err ) );
    }

    private _onDictValue( doc, prevValue ) {
        if ( this.getValue() !== prevValue || !doc ) {
            // Data changed
            return;
        }

        this.filledValue = prevValue;
        this.dictRecord = doc;
        this._fillInputReal( doc, prevValue );
        this.notify();
    }

    private _fillInputReal( doc, value ) {
        if ( this.fieldInfo.noKeys || this.fieldInfo.viewFormat === "label" )
            this.inputValue = doc.label;
        else
            this.inputValue = String( value ) + " - " + doc.label;
    }

    setInputValue(v: any): void {}

    assign( value: any ) {
        super.assign( value );
        this._fillInput();
        this.evalVisible();
    }

    cancelEdit(): void {
        super.cancelEdit();
        this._fillInput();
    }

    doneEdit(): void {
        super.doneEdit();
        this._fillInput();
    }

    setUpdate(v: any): void {
        super.setUpdate( v );
        this._fillInput();
    }

    // onSelectWindow() {
    //     let options = {
    //         mode: "view",
    //         docId: this.getValue(),
    //         target: "dialog",
    //         path: this.pathValue.getValue()
    //     };
    //     app.showView( options );
    // }

    onSelectClick() {
        if ( !this.dictPath )
            throw Error( "Unknown dict path" );

        let options = {
            mode: "list",
            viewMode: "select",
            target: "dialog",
            selectedValue: this.getValue(),
            path: this.dictPath,
            onOk: (res, pg) => {
                if ( res?.[0] ) {
                    this.onSelectResult( res[0] );
                    pg.close();
                }
            }
        }
        app.showView( options );
    }

    onSelectResult( doc ) {
        if ( !doc ) return;

        this.setUpdate( doc.name || doc.id );
    }

    clear() {
        this.setUpdate( null );
    }
}


export class InlineFieldValue extends FieldValue {
    subform: ViewDocPage;

    constructor( fieldInfo: FieldInfo, value: any, page: Page ) {
        super( fieldInfo, value, page );
        let options = {
            valueMode: "INLINE",
            docData: value,
            typeName: fieldInfo.typeName,
            target: "subform",
            readonly: fieldInfo.readonly
        }
        this.subform = new ViewDocPage( options );
    }

    async init() {
        await super.init();
        await this.subform.readyEvt.wait();
    };

    onDestroy(): void {
        super.onDestroy();
        this.subform.afterClose();
    }

    notify(): void {
        this.subform.notify();
    }

    setFieldInfo(fieldInfo: FieldInfo): void {
        if ( this.subform ) {
            if ( fieldInfo.typeName && fieldInfo.typeName != this.subform.typeName ) {
                this.subform.changeTypeTo( fieldInfo.typeName );
                this.subform.notify();
            }
        }
        super.setFieldInfo( fieldInfo );
    }

    startEdit(): void {
        this.editMode = true;
        this.subform.startEdit();
    }

    cancelEdit(): void {
        this.subform.cancelEdit();
        super.cancelEdit();
    }

    setUpdate(v: any): void {
        this.subform.setDocData( v );
        this.subform.notify();
        super.setUpdate( v );
    }

    assign(v: any): void {
        this.subform.setDocData( v );
        this.subform.notify();
        super.assign( v );
    }

    getValue() {
        return this.subform.getDoc();
    }

    getUpdate() {
        return this.subform.getDoc();
    }


}

export class SubformByIdValue extends FieldValue {
    subform: ViewDocPage;

    constructor( fieldInfo: FieldInfo, value: any, page: Page ) {
        super( fieldInfo, value, page );
        let options = {
            typeName: fieldInfo.typeName,
            path: fieldInfo.docsPath,
            docId: value,
            target: "subform",
            readonly: true,
            formName: "InlineViewForm"
        }
        this.subform = new ViewDocPage( options );
    }

    async init() {
        await super.init();
        await this.subform.readyEvt.wait();
    };

    onDestroy(): void {
        super.onDestroy();
        this.subform.afterClose();
    }

    notify(): void {
        this.subform.notify();
    }

    // setFieldInfo(fieldInfo: FieldInfo): void {
    //     if ( this.subform ) {
    //         if ( fieldInfo.typeName && fieldInfo.typeName != this.subform.typeName ) {
    //             this.subform.changeTypeTo( fieldInfo.typeName );
    //             this.subform.notify();
    //         }
    //     }
    //     super.setFieldInfo( fieldInfo );
    // }

    // startEdit(): void {
    //     this.editMode = true;
    //     this.subform.startEdit();
    // }

    // cancelEdit(): void {
    //     this.subform.cancelEdit();
    //     super.cancelEdit();
    // }

    // doneEdit(): void {
    //     if ( this.subform )
    //         this.update = this.subform.getDoc();

    //     super.doneEdit();
    // }

    setUpdate(v: any): void {
        this.subform.docId = v;
        this.subform.load();
        super.setUpdate( v );
    }

    assign(v: any): void {
        this.subform.docId = v;
        this.subform.load();
        super.assign( v );
    }
}

export class ChildrenFieldValue extends FieldValue {
    subform?: DocListPage;
    lastDocId: any;

    constructor( fieldInfo: FieldInfo, docId: string, page: Page ) {
        super( fieldInfo, null, page );
        let options : any = {
            mode: "list",
            path: fieldInfo.docsPath,
            filter: { idParent: { oper: "=", text: docId } },
            filterHidden: true,
            typeName: fieldInfo.typeName,
            target: "subform",
            readonly: true,
            form: "InlineListForm"
        }

        this.subform = new DocListPage( options );
        this.lastDocId = docId;
    }

    async init() {
        await super.init();
        await this.subform?.readyEvt.wait();
    };

    createSubform( docId ) {
        if ( this.subform ) {
            this.subform.afterClose();
            this.subform = undefined;
        }

        if ( docId != null ) {
            let options : any = {
                mode: "list",
                path: this.fieldInfo.docsPath,
                filter: { idParent: { oper: "=", text: docId } },
                filterHidden: true,
                typeName: this.fieldInfo.typeName,
                target: "subform",
                readonly: true,
                formName: "InlineListForm"
            }

            this.subform = new DocListPage( options );
        }
        this.lastDocId = this.page.docId;
    }

    notify(): void {
        // pass
    }

    onDestroy(): void {
        super.onDestroy();
        this.subform?.afterClose();
    }

    isEmpty() {
        return false;
    }

    setInputValue() {}

    startEdit(): void {
    }

    cancelEdit(): void {
    }

    doneEdit(): void {
    }

    setUpdate(v: any): void {
    }

    assign(v: any): void {
        if ( this.page.docId != this.lastDocId ) {
            this.createSubform( this.page.docId );
        }
    }

    getValue() {}

    getUpdate() {}
}

export class SubdocumentsFieldValue extends FieldValue {
    subform: DocListPage;

    constructor( fieldInfo: FieldInfo, docId: string, page: Page ) {
        super( fieldInfo, null, page );
        let options : any = {
            mode: "list",
            path: fieldInfo.docsPath,
            filter: { idParent: docId }, // default filter
            filterHidden: true,
            typeName: fieldInfo.typeName,
            target: "subform",
            readonly: true
        }

        if ( fieldInfo.filterField )
            options.filter = { [fieldInfo.filterField]: docId };

        this.subform = new DocListPage( options );
    }

    async init() {
        await super.init();
        await this.subform.readyEvt.wait();
    };

    notify(): void {
        // pass
    }

    onDestroy(): void {
        super.onDestroy();
        this.subform.afterClose();
    }

    setInputValue() {}

    startEdit(): void {
    }

    cancelEdit(): void {
    }

    doneEdit(): void {
    }

    setUpdate(v: any): void {
    }

    assign(v: any): void {
    }

    getValue() {}

    getUpdate() {}
}

export class IdArrayFieldValue extends FieldValue {
    subform: IdArrayPage;

    constructor( fieldInfo: FieldInfo, idArray: any[], page: Page ) {
        super( fieldInfo, idArray, page );
        let options : any = {
            mode: "list",
            valueMode: "ID_ARRAY",
            typeName: fieldInfo.typeName,
            target: "subform",
            path: fieldInfo.docsPath,
            readonly: this.fieldInfo.readonly,
            idArray
        }

        this.subform = new IdArrayPage( options );
    }

    async init() {
        await super.init();
        await this.subform.readyEvt.wait();
    };

    onDestroy(): void {
        super.onDestroy();
        this.subform.afterClose();
    }

    notify(): void {
        this.subform.notify();
    }

    startEdit(): void {
        this.editMode = true;
        this.subform.startEdit();
    }

    cancelEdit(): void {
        this.subform.endEdit();
        super.cancelEdit();
    }

    doneEdit(): void {
        let u = this.getUpdate();
        if ( u !== undefined )
            this.value = u;

        this.subform.endEdit();
        super.doneEdit();
    }

    setUpdate(v: any): void {
    }

    assign(v: any): void {
        this.value = v;
        this.subform.setIdArray( v );
    }

    getValue() {
        let u = this.getUpdate();
        return u === undefined ? this.value : u;
    }

    getUpdate() {
        let u = (this.subform as IdArrayPage).idArray;
        if ( u === this.value )
            return undefined;
        return u;
    }
}

export class InlineArrayFieldValue extends FieldValue {
    subform: InlineArrayPage;

    constructor( fieldInfo: FieldInfo, docs: any[], page: Page ) {
        super( fieldInfo, docs, page );
        let options : any = {
            mode: "list",
            valueMode: "INLINE_ARRAY",
            typeName: fieldInfo.typeName,
            target: "subform",
            path: fieldInfo.docsPath,
            readonly: this.fieldInfo.readonly,
            addFromPath: this.fieldInfo.addFromPath,
            addFilterScript: this.fieldInfo.addFilterScript,
            docs
        }

        this.subform = new InlineArrayPage( options );
    }

    async init() {
        await super.init();
        await this.subform.readyEvt.wait();
    };

    onDestroy(): void {
        super.onDestroy();
        this.subform.afterClose();
    }

    notify(): void {
        this.subform.notify();
    }

    startEdit(): void {
        this.editMode = true;
        this.subform.startEdit();
    }

    cancelEdit(): void {
        this.subform.endEdit();
        super.cancelEdit();
    }

    doneEdit(): void {
        let u = this.getUpdate();
        if ( u !== undefined )
            this.value = u;

        this.subform.endEdit();
        super.doneEdit();
    }

    setUpdate(v: any): void {
    }

    assign(v: any): void {
        this.value = v;
        this.subform.documents = v || [];
        this.subform.notify();
    }

    getValue() {
        let u = this.getUpdate();
        return u === undefined ? this.value : u;
    }

    getUpdate() {
        let u = this.subform.documents;
        if ( u === this.value )
            return undefined;
        return u;
    }
}

function fixOldDocs( docs ) {
    // TODO
    return docs;
}

export class FilesFieldValue extends FieldValue {
    subform: InlineArrayPage;
    tempId = -1;

    constructor( fieldInfo: FieldInfo, docs: any[], page: Page ) {
        docs = fixOldDocs( docs );

        super( fieldInfo, docs, page );

        let options : any = {
            mode: "list",
            valueMode: "INLINE_ARRAY",
            typeName: "DH_File",
            target: "subform",
            readonly: this.fieldInfo.readonly,
            isFiles: true,
            docs
        }

        this.subform = new InlineArrayPage( options );
        this.subform.onAdd = ()=>{
            app.showPage( createAddFilesPage( this.onAddFiles.bind( this ) ) );
        }
    }

    onAddFiles( files: File[] ) {
        let prev = this.getValue() || [];
        let newValue = [ ...prev, ...files.map( file => ( {
            id: this.tempId--,
            type: "DH_File",
            name: file.name,
            size: file.size,
            mimeType: file.type,
            _file: file
        } ) ) ];
        this.setUpdate( newValue );
    }

    async init() {
        await super.init();
        await this.subform.readyEvt.wait();
    };

    onDestroy(): void {
        super.onDestroy();
        this.subform.afterClose();
    }

    notify(): void {
        this.subform.notify();
    }

    startEdit(): void {
        this.editMode = true;
        this.subform.startEdit();
    }

    cancelEdit(): void {
        this.subform.endEdit();
        super.cancelEdit();
    }

    doneEdit(): void {
        let u = this.getUpdate();
        if ( u !== undefined )
            this.value = u;

        this.subform.endEdit();
        super.doneEdit();
    }

    setUpdate(v: any): void {
        this.update = v;
        this.subform.documents = v;
        this.subform.notify();
        this.onChange();
    }

    assign(v: any): void {
        this.value = v;
        this.subform.documents = v;
        this.subform.notify();
    }

    getValue() {
        let u = this.getUpdate();
        return u === undefined ? this.value : u;
    }

    getUpdate() {
        let u = this.subform.documents;
        if ( u === this.value )
            return undefined;
        return u;
    }
}

abstract class FieldFormat {
    fieldInfo: FieldInfo;

    constructor( fieldInfo: FieldInfo ) {
        this.fieldInfo = fieldInfo;
    }

    abstract toInput( value );
    abstract toValue( str );
    abstract checkInput( value ) : string | undefined;
}

class FieldFormatString extends FieldFormat {
    toInput( value ) {
        if ( value == null )
            return "";
        return value;
    }

    toValue( str ) {
        if ( str === "" )
            return null;

        if ( this.fieldInfo.zeros && this.fieldInfo.minlen ) {
            str = String( str ).padStart( this.fieldInfo.minlen, "0" );
        }
    
        return str;
    }

    checkValue( v ) {
        return this.checkInput( v );
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }

        if ( this.fieldInfo.digits && !String(value).match("^\\d+$" ) ) {
            return "Должны быть только цифры";
        }

        if ( this.fieldInfo.pattern ) {
            if ( !String(value).match(this.fieldInfo.pattern) )
                return this.fieldInfo.patternText || "Неверный формат строки"
        }

        if ( this.fieldInfo.minlen != null ) {
            let vlen = String(value).length;
            if ( this.fieldInfo.maxlen == this.fieldInfo.minlen ) {
                if ( vlen != this.fieldInfo.minlen ) {
                    return `Длина должна быть равна ${this.fieldInfo.minlen} (сейчас ${vlen})`;
                }
            } else {
                if ( vlen < this.fieldInfo.minlen ) {
                    return `Длина должна быть не меньше ${this.fieldInfo.minlen} (сейчас ${vlen})`;
                }
            }
        }
        
        if ( this.fieldInfo.maxlen != null ) {
            let vlen = String(value).length;
            if ( vlen > this.fieldInfo.maxlen )
                return `Длина должна быть не больше ${this.fieldInfo.maxlen} (сейчас ${vlen})`;
        }
    }
}

class FieldFormatInteger extends FieldFormat {
    toInput( value ) {
        if ( value == null )
            return "";
        return String(value);
    }

    toValue( str ) {
        if ( str === "" || str == null )
            return null;

        let num = parseInt( str, 10 );
        if ( isFinite( num ) )
            return num;
        
        return null;
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }

        let num = parseInt( value, 10 );
        if ( !isFinite( num ) )
            return "Должно быть целое число";
    }
}

class FieldFormatDate extends FieldFormat {
    static TextDateRE = /^\d{2}\.\d{2}\.\d{4}$/;
    static IsoDateRE = /^\d{4}-\d{2}-\d{2}$/;

    toInput( value ) {
        if ( value == null )
            return "";

        return utils.dateToText( value );
    }

    toValue( str ) {
        if ( str === "" || str == null )
            return null;

        if ( str instanceof Date ) {
            return utils.date2str( str );
        }

        if ( typeof str === "string" && FieldFormatDate.IsoDateRE.test( str ) )
            return str;

        return utils.textToDate( str );
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }

        if ( value instanceof Date )
            return;

        if ( FieldFormatDate.IsoDateRE.test( value ) )
            return;

        if ( !FieldFormatDate.TextDateRE.test( value ) )
            return "Неверный формат даты (ожидается ДД.ММ.ГГГГ)";
    }
}

class FieldFormatDateTime extends FieldFormat {
    static TextDateTimeRE = /^\d{2}\.\d{2}\.\d{4}(\s+\d{2}\:\d{2}(:\d{2})?)?$/;
    static IsoDateTimeRE = /^\d{4}-\d{2}-\d{2}/;
    static DateFormatRE = /^\d{2}\.\d{2}\.\d{4}$/;

    convertToIsoDate(dateString: string): string {
        // Проверяем, соответствует ли дата формату DD.MM.YYYY
        const DateFormatRE = /^\d{2}\.\d{2}\.\d{4}$/;
        if (!DateFormatRE.test(dateString)) {
            throw new Error("Invalid date format. Expected DD.MM.YYYY");
        }
    
        // Разбиваем строку на части: день, месяц, год
        const [day, month, year] = dateString.split(".");
    
        // Возвращаем дату в формате YYYY-MM-DD
        return `${year}-${month}-${day}`;
    }

    toInput( value ) {
        if ( value == null )
            return "";

        return utils.datetimeToText( value );
    }

    toValue( str ) {
        if ( str === "" || str == null )
            return null;

        if ( str instanceof Date ) {
            return utils.date2str( str );
        }

        if ( typeof str === "string" && FieldFormatDateTime.IsoDateTimeRE.test( str ) )
            return str;

        if ( typeof str === "string" && FieldFormatDateTime.DateFormatRE.test( str ))
            return this.convertToIsoDate(str);

        return utils.textToDatetime( str );
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }

        if ( value instanceof Date )
            return;

        if ( FieldFormatDateTime.IsoDateTimeRE.test( value ) )
            return;

        if ( !FieldFormatDateTime.TextDateTimeRE.test( value ) )
            return "Неверный формат даты (ожидается ДД.ММ.ГГГГ ЧЧ:ММ:CC)";
    }
}

class FieldFormatAmount extends FieldFormat {
    static amountRE = /^-?\d+(\.\d\d?)?$/;

    toInput( value ) {
        if ( value == null )
            return "";
        return String(value);
    }

    toValue( str ) {
        if ( str === "" || str == null )
            return null;

        if ( FieldFormatAmount.amountRE.test( str ) )
            return str;
        
        return null;
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }

        if ( FieldFormatAmount.amountRE.test( value ) )
            return "Неверный формат (ожидается 99999.99)";
    }
}

class FieldFormatNumber extends FieldFormat {
    static numberRE = /^-?\d+(\.\d+)?$/;

    toInput( value ) {
        if ( value == null )
            return "";
        return String(value);
    }

    toValue( str ) {
        if ( str === "" || str == null )
            return null;

        if ( FieldFormatNumber.numberRE.test( str ) )
            return str;
        
        return null;
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }

        if ( FieldFormatNumber.numberRE.test( value ) )
            return "Неверный формат (ожидается 99999.99)";
    }
}

class FieldFormatValues extends FieldFormat {
    toInput( value ) {
        if ( value == null )
            value = "";

        let values = this.fieldInfo.comboValues || this.fieldInfo.values;

        if ( !values )
            return value;

        let record = values.find( item => item[0] == value );
        if ( !record )
            return value;

        if ( this.fieldInfo.noKeys || values === this.fieldInfo.comboValues )
            return record[1];

        return record[0] + " - " + record[1];
    }

    toValue( str ) {
        if ( str === "" )
            return null;

        return str;
    }

    checkInput( value ) : string | undefined {
        if ( (value == "" || value == null) && this.fieldInfo.notNull ) {
            return "Поле должно быть заполнено";
        }
    }
}

class FieldFormatBoolean extends FieldFormat {
    toInput( value ) {
        return value ? "Да" : "Нет";
    }

    toValue( str ) {
        return str;
    }

    checkInput( value ) : string | undefined {
        return undefined;
    }
}

class FieldFormatFiles extends FieldFormat {
    toInput( value ) {
        return value;
    }

    toValue( str ) {
        return str;
    }

    checkInput( value ) : string | undefined {
        return undefined;
    }
}

class FieldFormatSubform extends FieldFormat {
    toInput( value ) {
        return value;
    }

    toValue( str ) {
        return str;
    }

    checkInput( value ) : string | undefined {
        return undefined;
    }
}
