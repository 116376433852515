import {Page} from './Page';
import React, { useEffect, useRef } from 'react';
//import 'highlight.js/styles/github.css';
//import hljs from 'highlight.js/lib/index.js';
//import ReactHighlightSyntax from 'react-highlight-syntax';

declare var hljs: any;

function MyHighlight( props ) {
    const ref = useRef(null);

    useEffect( ()=>{
        hljs.highlightElement(ref.current);
    }, [ props.text ] );

    return <div className="dh-body dh-scroll" style={{height: "100%"}}>
        <div className="dh-monospace" style={{whiteSpace: "pre"}} ref={ref}>{props.text}</div>
    </div>    
}

export class SourceViewPage extends Page {
    text: string;
    pages?: string[];
    language: string;

    constructor( props ) {
        super( props );
        this.text = props.text;
        this.pages = props.pages;
        this.language = props.language || "JSON";
    }

    getState() {
        let style = {
            whiteSpace: "pre"
        };

        // setTimeout( ()=>hljs.highlightAll(), 1000 )
        // setTimeout( ()=>hljs.highlightAll(), 100 )

        return {
            type: "Content",
            content: <MyHighlight text={this.text} />
            // <div className="dh-body dh-scroll" style={{height: "100%"}}>
            //     <div className="dh-monospace" style={style}>
            //         <ReactHighlightSyntax language={this.language as any} theme='Lightfair' copy={false}>{this.text}</ReactHighlightSyntax>
            //     </div>
            // </div>
        }
    }

    isEasyClose() { return true; }

    // renderForPrint() {
    //     if (this.pages){
    //         var list = [];
    //         for (var page of this.pages){
    //             var last = page === this.pages[this.pages.length]
    //             list.push(<pre style={{pageBreakAfter: last ? null :"always"}}>{page}</pre>);
    //         }
    //         return <React.Fragment>{list}</React.Fragment>
    //     }
    //     else {
    //         return <pre>{this.text}</pre>;
    //     }
    // }
}
