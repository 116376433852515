//import React, { Component } from 'react';
import { Page } from './Page';
import { ViewDocPage } from './ViewDocPage';
import { Application } from '../App';
import { DIALOG_ACTIONS } from '../constants';

declare var app: Application;

export function createStartPage() : Page {
    let options = { title: "Старт", layout: { type: "Label", value: "Стартовая страница" } };
    return new Page( options );
}

export function createInitPage(menu: any[]) {
    menu
        .sort((a, b) => a.position - b.position) // Сортировка по position
        .forEach(item => {
            if (item.fixed) {
                item.command(); // Вызов команды
            }
        });
};


export function createAboutPage( appInfo ) {
    let options = {
        mode: "view",
        valueMode: "INLINE",
        docData: appInfo,
        typeName: "_About",
        target: "dialog",
        dialogSize: [ "770px", "290px" ],
        actions: DIALOG_ACTIONS
    };

    return new ViewDocPage( options );
}

export function createLabelPage( options ) {
    options = {
        ...options,
        layout: {
            type: "VBox",
            elements: [ 
            {
                type: "Label",
                html: options.html,
                label: options.label,
                className: "dh-body",
                style: {
                    flex: "1 1 auto",
                    // margin: "4px 20px"
                },
            },
            {
                type: "Buttons",
                place: "dialog",
                className: "dh-dialog-buttons",
                style: {
                    flex: "0 0 auto",
                    // margin: "4px 20px"
                },
                visible: "model.isDialog()"
            } 
            ]
        },
        // actions: DIALOG_ACTIONS
    };

    return new Page( options );
}

export function createLoginPage() {
    let options = {
        mode: "view",
        valueMode: "INLINE",
        docData: {},
        typeName: "_Login",
        target: "dialog",
        viewMode: "edit",
        actions: DIALOG_ACTIONS,
        dialogSize: [ "770px", "290px" ],
        onOk: async ( doc, page: ViewDocPage ) => {
            try {
                page.incLoading();
                let res = await app.authSvc.login( doc.userName, doc.password );
                page.close();
                app.onLogin( res.session );
            } catch ( err: any ) {
                console.error( err );
                page.setError( err.message );
            } finally {
                page.decLoading();
            }
        }
    }

    let page = new ViewDocPage( options );
    // page.setActions( DIALOG_ACTIONS );
    return page;
}